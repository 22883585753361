<template>
    <div class="qingwu">
        <div class="admin_main_block">
            <div class="admin_main_block_top">
                <div class="admin_main_block_left">
                    <div>常用配置</div>
                </div>
            </div>

            <div class="admin_form_main">
                <el-tabs tab-position="left" style="min-height: 200px;">
                    <el-tab-pane label="客服电话"><wxpayH5></wxpayH5></el-tab-pane>
                    <el-tab-pane label="客服邮箱"><wxpayApp></wxpayApp></el-tab-pane>
                    <el-tab-pane label="公司logo"><wxpayJsApi></wxpayJsApi></el-tab-pane>
                    <el-tab-pane label="基本配置"><wxpayMini></wxpayMini></el-tab-pane>
                    <el-tab-pane label="积分商城"><alipayApp></alipayApp></el-tab-pane>
                    <el-tab-pane label="分享好友"><alipayPc></alipayPc></el-tab-pane>

                    <el-tab-pane label="总代转发"><zongdzf></zongdzf></el-tab-pane>
                    <el-tab-pane label="代理转发"><dailzf></dailzf></el-tab-pane>
                    <el-tab-pane label="普通转发"><putzf></putzf></el-tab-pane>

                    <el-tab-pane label="使用说明"><shiysmApp></shiysmApp></el-tab-pane>
                    <el-tab-pane label="购买须知"><alipayH5></alipayH5></el-tab-pane>
                    <!--<el-tab-pane label="支付宝APP"><alipayApp></alipayApp></el-tab-pane>
                    <el-tab-pane label="支付宝PC"><alipayPc></alipayPc></el-tab-pane>-->
                </el-tabs>
            </div>
        </div>
    </div>
</template>

<script>
import wxpayH5 from '@/components/admin/pay/wxpay_h5'
import wxpayApp from '@/components/admin/pay/wxpay_app'
import wxpayJsApi from '@/components/admin/pay/wxpay_jsapi'
import wxpayMini from '@/components/admin/pay/wxpay_mini'
import alipayH5 from '@/components/admin/pay/alipay_h5'
import alipayPc from '@/components/admin/pay/alipay_pc'


import zongdzf from '@/components/admin/pay/zongdzf'
import dailzf from '@/components/admin/pay/dailzf'
import putzf from '@/components/admin/pay/putzf'


import alipayApp from '@/components/admin/pay/alipay_app'
import shiysmApp from '@/components/admin/pay/shiysm_app'
export default {
    components: {
        wxpayH5,
        wxpayApp,
        wxpayJsApi,
        wxpayMini,
        alipayH5,
        alipayPc,
         alipayApp,
        shiysmApp,
        zongdzf,
        dailzf,
        putzf
    },
    props: {},
    data() {
      return {
      };
    },
    watch: {},
    computed: {},
    methods: {},
    created() {},
    mounted() {}
};
</script>
<style lang="scss" scoped>

</style>