<template>
    <div class="qingwu">
        <el-form  label-width="300px" ref="info" :model="info">
            <el-form-item label-width="240px" label="成为分销商费用" prop="fenxsfy" :rules="[{required:true,message:'不能为空',trigger: 'blur' }]"><el-input type="number" class="spec_group_input" v-model="info.fenxsfy" placeholder="0"><template slot="append">元</template></el-input></el-form-item>
            <el-form-item label-width="240px" label="满多少包邮" prop="manby" :rules="[{required:true,message:'不能为空',trigger: 'blur' }]"><el-input type="number" class="spec_group_input" v-model="info.manby" placeholder="0"><template slot="append">元</template></el-input></el-form-item>
            <el-form-item label-width="240px" label="快递费" prop="kuaidf" :rules="[{required:true,message:'不能为空',trigger: 'blur' }]"><el-input type="number" class="spec_group_input" v-model="info.kuaidf" placeholder="0"><template slot="append">元</template></el-input></el-form-item>
            <el-form-item label-width="240px" label="普通用户自购产品1元等于积分" prop="tuijjf" :rules="[{required:true,message:'不能为空',trigger: 'blur' }]"><el-input type="number" class="spec_group_input" v-model="info.tuijjf" placeholder="0"><template slot="append">分</template></el-input></el-form-item>
           <el-form-item label-width="240px" label="普通用户分享得下单积分" prop="fenxdjf" :rules="[{required:true,message:'不能为空',trigger: 'blur' }]"><el-input type="number" class="spec_group_input" v-model="info.fenxdjf" placeholder="0"><template slot="append">分</template></el-input></el-form-item>
            <el-form-item label-width="240px" label="平台单笔佣金" prop="danbyj" :rules="[{required:true,message:'不能为空',trigger: 'blur' }]"><el-input type="number" class="spec_group_input" v-model="info.danbyj" placeholder="0"><template slot="append">元</template></el-input></el-form-item>
            <el-form-item label-width="240px" label="一级抽二级佣金" prop="yijcejyj" :rules="[{required:true,message:'不能为空',trigger: 'blur' }]"><el-input type="number" class="spec_group_input" v-model="info.yijcejyj" placeholder="0"><template slot="append">元</template></el-input></el-form-item>
            <el-form-item label-width="240px" label="二级拉满多少人脱离一级" prop="erjtl" :rules="[{required:true,message:'不能为空',trigger: 'blur' }]"><el-input type="number" class="spec_group_input" v-model="info.erjtl" placeholder="0"><template slot="append">人</template></el-input></el-form-item>
            <el-form-item label-width="240px" label="平台奖励原一级安慰奖佣金" prop="anwj" :rules="[{required:true,message:'不能为空',trigger: 'blur' }]"><el-input type="number" class="spec_group_input" v-model="info.anwj" placeholder="0"><template slot="append">元</template></el-input></el-form-item>
            <el-form-item label-width="240px" label="购买满多少为黄金会员" prop="huangjhyje" :rules="[{required:true,message:'不能为空',trigger: 'blur' }]"><el-input type="number" class="spec_group_input" v-model="info.huangjhyje" placeholder="0"><template slot="append">元</template></el-input></el-form-item>
            <el-form-item label-width="240px" label="黄金会员折扣" prop="huangjhyzk" :rules="[{required:true,message:'不能为空',trigger: 'blur' }]"><el-input type="number" class="spec_group_input" v-model="info.huangjhyzk" placeholder="0"><template slot="append">%</template></el-input></el-form-item>
            <el-form-item label-width="240px" label="购买满多少为铂金会员" prop="bojhyje" :rules="[{required:true,message:'不能为空',trigger: 'blur' }]"><el-input type="number" class="spec_group_input" v-model="info.bojhyje" placeholder="0"><template slot="append">元</template></el-input></el-form-item>
            <el-form-item label-width="240px" label="铂金会员折扣" prop="bojhyzk" :rules="[{required:true,message:'不能为空',trigger: 'blur' }]"><el-input type="number" class="spec_group_input" v-model="info.bojhyzk" placeholder="0"><template slot="append">%</template></el-input></el-form-item>
            <el-form-item label-width="240px" label="购买满多少为钻石会员" prop="zuanshyje" :rules="[{required:true,message:'不能为空',trigger: 'blur' }]"><el-input type="number" class="spec_group_input" v-model="info.zuanshyje" placeholder="0"><template slot="append">元</template></el-input></el-form-item>
            <el-form-item label-width="240px" label="钻石会员折扣" prop="zuanshyzk" :rules="[{required:true,message:'不能为空',trigger: 'blur' }]"><el-input type="number" class="spec_group_input" v-model="info.zuanshyzk" placeholder="0"><template slot="append">%</template></el-input></el-form-item>
            <el-form-item label-width="240px" label="下级满多少人脱离原一级" prop="tuolyjrs" :rules="[{required:true,message:'不能为空',trigger: 'blur' }]"><el-input type="number" class="spec_group_input" v-model="info.tuolyjrs" placeholder="0"><template slot="append">人</template></el-input></el-form-item>

            <!--<el-form-item label-width="160px" label="APPID" prop="appid" :rules="[{required:true,message:'APPID不能为空',trigger: 'blur' }]"><el-input placeholder="请输入内容" v-model="info.appid"></el-input></el-form-item>
            <el-form-item label-width="160px" label="APPSECRET" prop="appsecret" :rules="[{required:true,message:'APPSECRET不能为空',trigger: 'blur' }]"><el-input placeholder="请输入内容" v-model="info.appsecret"></el-input></el-form-item>-->
            <!--<el-form-item label-width="160px" label="备注："><el-tag type="info">新客下单减</el-tag></el-form-item>-->
            <el-form-item label-width="160px">
                <el-button type="primary" @click="submitForm('info')">提交</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
export default {
    components: {},
    props: {},
    data() {
      return {
          info:{},
      };
    },
    watch: {},
    computed: {},
    methods: {
        submitForm:function(e){
            this.$refs[e].validate(res=>{
                if(res){
                    // Http 请求
                    this.$post(this.$api.wxPayMiNiConfig,this.info).then(postRes=>{
                        if(postRes.code == 200){
                            this.$message.success("编辑成功");
                            this.get_wxpaymini_config();
                        }else{
                            this.$message.error("编辑失败");
                        }
                    });
                }
            });
        },
        get_wxpaymini_config(){
            this.$get(this.$api.wxPayMiNiConfig).then(res=>{
                if(res.data != null){
                    this.info  = res.data;
                }
            });
        },
    },
    created() {
        this.get_wxpaymini_config();
    },
    mounted() {}
};
</script>
<style lang="scss" scoped>
    .spec_group_input{
        margin:0 auto;
        width: 150px;
    }
</style>