<template>
    <div class="qingwu">
        <el-form  label-width="100px" ref="info" :model="info">
            <el-form-item label-width="160px" label="有效期" prop="youxq" :rules="[{required:true,message:'有效期不能为空',trigger: 'blur' }]"><el-input placeholder="请输入内容" v-model="info.youxq" style="width:360px;"></el-input></el-form-item>
            <el-form-item label-width="160px" label="预约信息" prop="yuyxx" :rules="[{required:true,message:'预约信息不能为空',trigger: 'blur' }]"><el-input placeholder="请输入内容" v-model="info.yuyxx" style="width:360px;"></el-input></el-form-item>
            <el-form-item label-width="160px" label="适用人数" prop="shiyrs" :rules="[{required:true,message:'适用人数不能为空',trigger: 'blur' }]"><el-input placeholder="请输入内容" v-model="info.shiyrs" style="width:360px;"></el-input></el-form-item>
            <el-form-item label-width="160px" label="规则提醒" prop="guiztx" :rules="[{required:true,message:'规则提醒不能为空',trigger: 'blur' }]"><el-input placeholder="请输入内容" v-model="info.guiztx" style="width:360px;"></el-input></el-form-item>
            <el-form-item label-width="160px" label="温馨提示" prop="wenxts" :rules="[{required:true,message:'温馨提示不能为空',trigger: 'blur' }]"><el-input type="textarea" placeholder="请输入内容" v-model="info.wenxts" :rows="7" style="width:360px;"></el-input></el-form-item>
            <el-form-item label-width="160px" label="备注："><el-tag type="info">购买须知</el-tag></el-form-item>
            <el-form-item label-width="160px">
                <el-button type="primary" @click="submitForm('info')">提交</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
export default {
    components: {},
    props: {},
    data() {
      return {
          info:{},
      };
    },
    watch: {},
    computed: {},
    methods: {
        submitForm:function(e){
            this.$refs[e].validate(res=>{
                if(res){
                    // Http 请求
                    this.$post(this.$api.aliPayH5Config,this.info).then(postRes=>{
                        if(postRes.code == 200){
                            this.$message.success("编辑成功");
                            this.get_alipayh5_config();
                        }else{
                            this.$message.error("编辑失败");
                        }
                    });
                }
            });
        },
        get_alipayh5_config(){
            this.$get(this.$api.aliPayH5Config).then(res=>{
                if(res.data != null){
                    this.info  = res.data;
                }
            });
        },
    },
    created() {
        this.get_alipayh5_config();
    },
    mounted() {}
};
</script>
<style lang="scss" scoped>

</style>